<template>


	<!-- for header part -->
	<header style="background-color: #fefefe; padding-bottom: 10px; border-bottom: solid 1px #ddd; " class="top-bar" > 

		<div class="logosec" style="margin-top: 10px;"> 
			<router-link to="/home"><img src="/assets/images/logo.png" class="logo"> </router-link>
			<!--<button class="btn btn-lg menuicn color-1" id="menuicn" @click="header()"> <i class="bi bi-grid-fill"></i> </button>-->

		</div> 

		<div class="searchbar"> 
		New to Maifes? <router-link to="/tutorials" style="margin-left: 10px;" class="btn btn-1"> View Maifes Tour</router-link>
		</div> 


			<div class="message2">

			<router-link v-if="this.$store.state.was_topic_clicked=='no'" to="/waiting-room" class="spinner-grow text-danger" role="status">
  			<span class="visually-hidden">Loading...</span>
			</router-link>
 	
			<router-link to="/create-post" class="btn btn-lg"><i class="bi bi-plus-square"></i></router-link>
			
			<router-link to="/notifications" class="btn btn-lg"> <i class="bi bi-heart"></i> </router-link> 

			<button class="btn btn-lg" id="menuicn" @click="header()"> <i class="bi bi-grid-fill"></i> </button>
		 
		</div>

		<div class="message"> 

			<router-link v-if="this.$store.state.am_in_the_waiting_room=='yes'" to="/waiting-room" class="spinner-grow text-danger" role="status">
  			<span class="visually-hidden">Loading...</span>
			</router-link>


			<router-link v-if="this.$store.state.am_in_klub_waiting_room=='yes'" to="/klub-waiting-room" class="spinner-grow text-danger" role="status">
  			<span class="visually-hidden">Loading...</span>
			</router-link>

			<router-link to="/create-post" class="btn"><i class="bi bi-plus-square"></i></router-link>
			
			<router-link to="/notifications" class="btn btn-lg"> <i class="bi bi-heart"></i> </router-link> 

			<button @click="logout()" class="btn btn-lg"> <i class="bi bi-power"></i> </button> 
		 
		</div>


	 

	</header> 




	<div class="bottom-bar">
		
		<router-link to="/home" class="btn btn-lg"> <i class="bi bi-house"></i> </router-link>
		<router-link to="/join-chat" class="btn btn-lg"> <i class="bi bi-chat-right-dots"></i> </router-link>
		<router-link to="/klub" class="btn btn-lg"> <i class="bi bi-share"></i> </router-link> 
		<router-link to="/trending" class="btn btn-lg"> <i class="bi bi-bar-chart"></i> </router-link> 
	<router-link to="/saved"  class="btn btn-lg"><i class="bi bi-save2"></i></router-link> 
		<router-link to="/profile" class="btn btn-lg"><i class="bi bi-gear"></i></router-link>

	</div>
	

</template>

<script>
	
	export default{
		name : 'header',
		methods : {
			header(){
		let menuicn = document.querySelector(".menuicn"); 
let nav = document.querySelector(".navcontainer"); 
nav.classList.toggle("navclose"); 


			},

				logout(){
				localStorage.clear()
				this.$router.push('/')
			}
		},

		created(){

			if(this.$store.state.phone=='na'){
				this.$router.push('/add-phone')
			}
			
		}
	}

</script>