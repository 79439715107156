<template>


	<TopBar />

	<div class="main-container">


	<SideBar />
 
		 


		<div class="main"> 



			<div class="row" style="padding-top:40px;">

				<div class="col-md-6">


			<div style="padding-top: 40px;">

				

				<div class="row">

					<div class="col-md-6 col-6">
						<router-link  class="btn p-0 color-1" to="/notifications"> <i class="bi bi-chevron-left"></i> Back </router-link>
					</div>

					<div class="col-md-6 col-6">
						<div style="text-align:right;">
					<button @click="delete_friend()" class="btn btn-1">Remove friend!</button>
				</div>
					</div>
					
				</div>

				<h4 class="mt-3"> {{title}} </h4>


				<div>
					
		<p class="custom-message" style="word-wrap: break-word; max-width: 500px;">
		 <div class="row">
		 	<div class="col-md-1 col-1">
		 		<i class="bi bi-person-circle color-1"></i>
		 	</div>
		 	<div class="col-md-9 col-9"> {{comment}}  </div>
		 	<div class="col-md-2 col-2" style="text-align:right;">
		 		<button @click="like_comment()" class="btn"> <i class="bi bi-heart-fill color-1"></i> </button>
		 	</div>
		 </div> 
		</p>
				
				</div>



				<div style="min-height:100px;">
					
				<div style="padding-left:20px;">

					<p v-for="r in replies" style="background-color: #f5f5f5;  padding: 10px; border-radius: 5px;" >
						
						<div class="row">
		 	<div class="col-md-1 col-2">
		 		<i class="bi bi-person-circle color-1"></i>
		 	</div>
		 	<div class="col-md-9 col-8"> {{r.message}} </div>
		 	<div class="col-md-2 col-2" style="text-align:right;">
		 		<button class="btn" @click="delete_reply(r.id)"> <i class="bi bi-x"></i> </button>
		 	</div>
		 </div>

					</p>

					
				</div>

				</div>





								<!--form section-->
<div style="padding: 10px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; background-color: #fff; ">

<div class="input-group">
  <input  @input="get_characters()" @keyup.enter="send_reply()" type="text" class="form-control" placeholder="Type a message" aria-label="Type a message" aria-describedby="button-addon2"  v-model="message">
  <button :disabled="disabled" @click="send_reply()" class="btn btn-2" type="button" id="button-addon2"> <i class="bi bi-chevron-right"></i> </button>
</div>


<div class="mt-1">
	<label style="border:solid 2px #f75318; padding: 6px; border-radius: 50px; color: #f75318; height: 30px; width: 30px; text-align: center; font-size:10px; "> {{character_limit}} </label>
</div>

</div>


				
			</div>
						

				</div>



				<div class="col-md-6">
					
				</div>

				
			</div>

	

		

			
		</div> 
	</div> 
	


</template>

<script>
	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import axios from 'axios'
	import { io } from "socket.io-client"

	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				posts : [],
				comment : [],
				socket : io(this.$store.state.socketio_server),
				id : '',
				post_id : '',
				title : '',
				message : '',
				comment_owner : '',
				replies : [],
				disabled : false,
				character_limit : 500
			}
		},

		methods : {


			get_characters(){
				let c = this.message.length
				this.character_limit = 500 - c
				if(this.character_limit<=0){
					this.character_limit = 0
					this.disabled = true
					
				}

				if(this.character_limit>0){
					this.disabled = false
				}

			},

				async delete_friend(){
				const res = await axios.post(this.$store.state.url+'delete-friend',{
					user_id : this.$store.state.user_id,
					friend : this.comment_owner
				}).then(function(response){
					return response.data 
				}).catch(function(error){
					//console.log(error)
				})
				this.$router.push('/notifications')
				
			},

		like_comment(){
			this.socket.emit('like comment',{ 'user_id' : this.$store.state.user_id, 'post_id' : this.post_id, 'comment_id' : this.id  })
		},


			async delete_reply(id){
				const res = await axios.post(this.$store.state.url+'delete-reply',{
					id : id
				}).then(function(response){
					return response.data
				}).catch(function(erorr){
					//console.log(error)
				})
				 this.comment_replies() 
			},

			send_reply(){

				if(!this.message){
					return
				}


				if(this.character_limit<=0){
					return
				}

				this.socket.emit('send reply',{ 'user_id' : this.$store.state.user_id, 'comment_id' : this.id, 'post_id' : this.post_id, 'comment_owner' : this.comment_owner, 'message' : this.message })

				this.message = ''
				this.comment_replies()

			},



			async comment_replies(){
				const res = await axios.get(this.$store.state.url+'comment-replies/'+this.id).then(function(response){
					return response.data
				}).catch(function(erorr){
					//console.log(error)
				})
				 this.replies = res 
			},


			async comment_details(){
				const res = await axios.get(this.$store.state.url+'comment-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(erorr){
					//console.log(error)
				})
				res.forEach((data)=>{
					this.comment = data.message
					this.post_id = data.post_id
					this.comment_owner = data.user_id

				})

				this.post_details(this.post_id)
				 
			},

			async post_details(post_id){
				const res = await axios.get(this.$store.state.url+'post-details/'+post_id).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})
				this.details = res
				res.forEach((data)=>{
					this.title = data.postTitle
					
				}) 
			}
		},

		created(){
		this.id = this.$route.params.id
		this.comment_details()
		this.comment_replies()


		}

	}
</script>

