<template>


<section style="padding-top: 100px;" :class=" { 'blur' : show_modal } ">
	
	<div class="container">

		<div class="row">

<div class="col-md-2"></div>

			<div class="col-md-4 animate__animated animate__bounceIn animate__slower" style="background-image: url('/assets/images/Untitled design.png'); background-size: contain; background-repeat: no-repeat; background-position: center;" >
	
			</div>

			<div class="col-md-4" style="padding-bottom:0px;">
				
				<div style="border: solid 1px  #f0f0f0; padding-top: 50px; padding-bottom: 50px; padding-left:30px; padding-right: 30px; border-radius: 15px;">

					<div style="padding-bottom: 30px; text-align: center;">
						<img src="/assets/images/logo.png" class="logo">
					</div>

					<!--
					<div style="text-align:center;">
						<p v-if="message" :class="text_class"> {{message}} </p>
					<p v-if="show_loader"> <div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div> Login you in...</p>
					</div>-->
					
					<form @submit.prevent="register()" method="post">
						
						<div>
							<input type="text" placeholder="Email address" class="form-control" v-model="email">
						</div>

						<div class="mt-2">
							<input type="text" placeholder="Phone number" class="form-control" v-model="phone">
						</div>


						<div class="mt-2">
							<input type="password" placeholder="Password" class="form-control" v-model="password">
						</div>

						<div class="mt-2">
							<button class="btn btn-1 w-100" :disabled="disabled"> {{text}} </button>
						</div>

						<div class="mt-2" style="text-align:center;">
							<h6>OR</h6>
						</div>


						<div class="mt-2">
							<button type="button" @click="google_register()" class="btn btn-2 w-100"> <i class="bi bi-google"></i> Sign Up With Google</button>
						</div>

						

					</form>

				</div>


				<div style="border: solid 1px  #f0f0f0; border-radius: 15px; text-align: center; padding:30px;"class="mt-3">
					Already have an account? <router-link to="/" class="btn p-0 color-1">Login</router-link>
				</div>

			</div>
			<div class="col-md-4"></div>
			
		</div>
		
	</div>


</section>





<div v-if="show_modal" style="position:fixed; right: 0; top: 0; width: 100%; padding-top: 200px; ">
	
	<div class="container">
		<div class="row">
			<div class="col-md-4"></div>
			<div class="col-md-4">
				
				<div class="shadow-lg" style="text-align:center; background-color:#fff; border-radius: 15px; padding: 30px;">
					
					<h1 class="color-1"> <i class="bi bi-check-circle"></i> </h1>
					<h3> <b>Success</b> </h3>
					<p> Account created successfully </p>

					<div>
						
						<router-link to="/tutorials" class="btn btn-2" style="margin-right: 10px;">Maifes Tour</router-link>

						<router-link to="/home" class="btn btn-1">Login</router-link>

					</div>

				</div>

			</div>
			
		</div>
		
	</div>

</div>



</template>

<script>

	import axios from 'axios'
	import { googleSdkLoaded } from "vue3-google-login"
	import 'animate.css'

	
	export default{
		name : 'register',
		data(){
			return{
				email : '',
				phone : '',
				password : '',
				message : '',
				text : 'Sign Up',
				disabled : false,
				show_loader : false,
				user_details : '',
				google_password : '',
				show_modal : false

			}
		},

		methods : {


			 google_register() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id:
              "1005035349-r5jl8j1pe9sk7mmcmj9gngeshobt3u5h.apps.googleusercontent.com",
            scope: "email profile openid",
            redirect_uri: "http://127.0.0.1:8080",
            callback: response => {
              if (response.code) {
              	this.sendCodeToBackend(response.code)
                ////console.log(response);
              }
            }
          })
          .requestCode();
      });
    },


        async sendCodeToBackend(code) {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            code,
            client_id:
              "1005035349-r5jl8j1pe9sk7mmcmj9gngeshobt3u5h.apps.googleusercontent.com",
            client_secret: "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",
            redirect_uri: "postmessage",
            grant_type: "authorization_code"
          }
        );

        const accessToken = response.data.access_token;
        //console.log(accessToken);

        // Fetch user details using the access token
        const userResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          this.user_details = userResponse.data;
           this.email = this.user_details.email
           this.google_register_now()
           
        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Token exchange failed:", error.response.data);
      }
    },


			async login(){

				const res = await axios.post(this.$store.state.url+'google-login',{
					email : this.email
					
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{
						localStorage.setItem('email',data.email)
						localStorage.setItem('phone',data.phone)
						localStorage.setItem('user_id',data.userId)
						this.$store.state.user_id = data.userId
						localStorage.setItem('logged_in','yes')
						this.$store.state.logged_in = 'yes'

						localStorage.setItem('background',data.background)
						this.$store.state.background = data.background

						localStorage.setItem('created',data.created)
						this.$store.state.created = data.created

					})

					//this.$router.push('/home')
					this.show_modal = true

				}else{
					this.message = 'Wrong email or password!'
				}
			},

				async register(){

				if(!this.email){
					this.text_class = 'text-danger'
					this.message = 'Email is required!'
					return
				}

				if(!this.phone){
					this.text_class = 'text-danger'
					this.message = 'Phone is required!'
					return
				}

				if(!this.password){
					this.text_class = 'text-danger'
					this.message = 'Password is required!'
					return
				}


				this.text = 'Please Wait...'
				this.disabled = true

				const res = await axios.post(this.$store.state.url+'register',{
					email : this.email,
					password : this.password,
					phone : this.phone
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})

				if(res=='success'){
					this.text_class = 'text-success'
					this.message = 'Account created successfully'
					this.show_loader = true
					setTimeout(()=>{
						this.login()
					}, 5000);

					
				}else{
					this.text = 'Sign Up'
				this.disabled = false
					this.text_class = 'text-danger'
					this.message = 'An account with that email already exists!'
				}
				
			},





			async google_register_now(){


				const res = await axios.post(this.$store.state.url+'google-register',{
					email : this.email,
					password : this.google_password,
					phone : ''
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})

				if(res=='success'){
					this.text_class = 'text-success'
					this.message = 'Account created successfully'
					this.show_loader = true
					setTimeout(()=>{
						this.login()
					}, 5000);

					
				}else{
				
					this.text_class = 'text-danger'
					this.message = 'An account with that email already exists!'
				}
				
			}

		},

		created(){
		this.google_password = Math.floor(Math.random() * 1000).toString()
		this.google_password = 'maifes'+this.google_password
		//console.log(this.google_password)

		}

	}

</script>


