<template>


<TopBar />

<div class="main-container">


<SideBar />


<div class="main"> 




<div class="row" style="padding-top:20px;">

<div class="col-md-6" style="padding:10px;">


<div style="overflow: auto; white-space: nowrap; padding-bottom: 1px;">
<router-link @mouseleave="title_modal=false" @mouseover="show_title(p.post_title)" :to=" { name : 'view-post', params : { post_id : p.post_id } } " v-for="p in posts" class="btn post-btn" :style=" { 'display' : 'inline-block', 'backgroundColor' : 'rgb('+p.r+','+p.g+','+p.b+')' } ">  {{ p.post_title[0] }}  </router-link>

</div>




<div style="padding-top:30px;">



<div class="row mb-3">

<div class="col-md-6 col-6">
<h4>Maifes<span class="color-1"><i class="bi bi-dot"></i></span>Home</h4>
</div>

<div class="col-md-6 col-6" style="text-align:right;">
<router-link to="/join-chat" class="btn btn-outline-warning">Join Chat</router-link>
</div>

</div>




<textarea @input="get_characters()" @keyup.enter="create_topic()" placeholder="What's on your mind?" class="form-control" rows="6" v-model="topic_name"></textarea>



<div class="row">
<div class="col-md-6 col-6">

<button :disabled="disabled || this.$store.state.am_in_the_waiting_room=='yes' "  @click="create_topic()" class="btn btn-1 mt-2"> <i class="bi bi-plus"></i> Create</button>

</div>

<div class="col-md-6 col-6 p-2" style="text-align:right;">
<button v-if="topic_name" @click="clear()" class="btn color-3"> <i class="bi bi-x"></i> Clear</button>
<label style="border:solid 2px #f75318; padding: 6px; border-radius: 50px; color: #f75318; height: 40px; width: 40px; text-align: center; "> {{character_limit}} </label>
</div>

</div>

</div>



<div style="padding-top: 40px;">

<h4>Trending</h4>

<div>

<div v-for="t in topics">

<button :disabled="this.$store.state.am_in_the_waiting_room=='yes'" @click="create_from_trending(t.topicName)" class="btn p-2 mt-3 trending-topic-btn animate__animated animate__fadeInUp animate__slow">

<div class="row">

<div class="col-md-1 col-2" style="border-right: solid 1px  #ffcabd;">
<label style="background-color: #ffe5df; height: 30px; width: 100%; text-align:center; padding: 3px; border-radius: 10px; "> <i class="bi bi-check-circle"></i></label>
</div>

<div class="col-md-11 col-10">
{{t.topicName}}
</div>

</div>

</button>

</div>

</div>


</div>



</div>



<div class="col-md-6">


<div style="padding: 10px; ">

<div> 
<h4>Save for later</h4>
</div>



<div style="padding-top: 10px;">

<div v-for="t in topics">
<button @click="save_for_later(t.topicHash)" class="btn p-2 mt-3 border-bottom trending-topic-btn animate__animated animate__fadeInUp animate__slow">

<div class="row">

<div class="col-md-1 col-2" style="border-right: solid 1px  #ffcabd;">
<label style="background-color: #ffe5df; height: 30px; width: 100%; text-align:center; padding: 3px; border-radius: 10px; "> <i class="bi bi-cloud-download"></i></label>
</div>

<div class="col-md-11 col-10">
{{t.topicName}}
</div>

</div>

</button>
</div>


</div>


</div>



<div style="padding-top:70px; text-align: left;">
<h6>Maifes Inc.All rights reserved</h6>
</div>

</div>


</div>






</div> 
</div> 




<div style="z-index: 1200; margin: 30px; position: absolute; right: 0; top: 0; max-width: 350px; " v-if="title_modal">

<div style="background-color:#fff; text-align: left; " class="shadow-lg rounded p-3 animate__animated animate__fadeInUp">
{{post_title}}
</div>

</div>



</template>

<script>
import SideBar from './layouts/SideBar'
import TopBar from './layouts/TopBar'
import axios from 'axios'
import 'animate.css'
import { io } from "socket.io-client"

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css'

export default{
name : 'home',
components : { SideBar, TopBar },
data(){
return{
topics : [],
message : '',
topic_name : '',
posts : [],
title_modal : false,
post_title : '',
character_limit : 150,
disabled : false,
bad_words : ['fuck','matako', 'pussy', 'nigga' ],
disabled2 : false,
socket : io(this.$store.state.socketio_server),

}
},

methods : {

leave(){
//leave waiting room when someone clicks back
this.socket.emit('leave waiting room', { 'room' : this.$store.state.current_topic_hash } )
clearInterval(this.x)
localStorage.setItem('was_topic_clicked','yes')
this.$store.state.was_topic_clicked = 'yes'

localStorage.setItem('am_in_the_waiting_room','no')
this.$store.state.am_in_the_waiting_room = 'no'

localStorage.setItem('distance2','')
this.$store.state.distance2 = localStorage.getItem('distance2')


},

check_words(){
this.bad_words.forEach((data)=>{

if(this.topic_name.includes(data)){
this.disabled = true

}


})


},

clear(){
this.topic_name = ''
this.character_limit = 150
},

get_characters(){



let c = this.topic_name.length
this.character_limit = 150 - c
if(this.character_limit<=0){
this.character_limit = 0
this.disabled = true

}

if(this.character_limit>0){
this.disabled = false
}


this.check_words()			

},

show_title(title){
this.post_title = title
this.title_modal = true
},

create_from_trending(topic_name){
this.topic_name = topic_name
this.create_topic()
},

create_topic(){
if(!this.topic_name){
return
}

localStorage.setItem('current_topic_name',this.topic_name)
this.$store.state.current_topic_name = this.topic_name
let hash = Date.now()
localStorage.setItem('current_topic_hash',hash)
this.$store.state.current_topic_hash = hash

this.create_topic_now()

localStorage.setItem('am_in_the_waiting_room','yes')
this.$store.state.am_in_the_waiting_room = 'yes'

localStorage.setItem('was_topic_clicked','no')
this.$store.state.was_topic_clicked = 'no'

this.$router.push('/waiting-room')

},


async create_topic_now(){
const res = await axios.post(this.$store.state.url+"create-topic",{
user_id : this.$store.state.user_id,
topic_hash : this.$store.state.current_topic_hash,
topic_name : this.$store.state.current_topic_name,
klub : 0
}).then(function(response){
return response.data
}).catch(function(error){
//console.log(error)
})

if(res=='success'){
this.message = 'Topic added to your library!'
}

},




async save_for_later(topic_hash){
this.message = ''
const res = await axios.post(this.$store.state.url+"save-for-later",{
user_id : this.$store.state.user_id,
topic_hash : topic_hash
}).then(function(response){
return response.data
}).catch(function(error){
//console.log(error)
})

if(res=='success'){
//this.message = 'Topic added to your library!'
toast.success("Topic added to your library", {
autoClose: 2000,
})
}

},


async get_topics(){
const res = await axios.get(this.$store.state.url+"trending-topics").then(function(response){
return response.data
}).catch(function(error){
//console.log(error)
})
this.topics = res 
},


async get_posts(){
const res = await axios.get(this.$store.state.url+"get-posts/"+this.$store.state.user_id).then(function(response){
return response.data
}).catch(function(error){
//	console.log(error)
})
this.posts = res 
}


},


created(){

//reset waiting room time
localStorage.setItem('distance2','')
this.$store.state.distance2 = ''


//reset chatting time
localStorage.setItem('distance','')
this.$store.state.distance = ''

if(this.$store.state.current_topic_hash){
this.leave()
}

/*
if(localStorage.getItem('distance2')==''){

localStorage.setItem('distance2',180000)
this.$store.state.distance2 = localStorage.getItem('distance2')	
}
*/


this.get_topics()
this.get_posts()
localStorage.setItem('chatting_in_progress','no')
this.$store.state.chatting_in_progress = localStorage.getItem('chatting_in_progress')
}

}
</script>

