<template>


<section style="padding-top: 100px;">
	
	<div class="container">

		<div class="row">


			<div class="col-md-4">
			
			</div>
			<div class="col-md-4">
				
				<div style="border: solid 1px  #f0f0f0;" class="rounded p-3">

					<div style="padding-bottom: 30px; text-align: center;">
						<img src="/assets/images/logo.png" class="logo">
					</div>
					
					<p :class="text_class" v-of="message"> {{message}} </p>

					<form method="post" @submit.prevent="forgot_password()">
						
						<div>
							<input type="text" v-model="email" placeholder="Email address" class="form-control">
						</div>


						<div class="mt-2">
							<button class="btn btn-1 w-100">Get Link</button>
						</div>

						<div class="mt-3" style="text-align: center;">
							<router-link to="/" class="btn p-0 ">Back to login</router-link>
						</div>

					</form>

				</div>


				<div style="border: solid 1px  #f0f0f0;" class="rounded p-3 mt-3">
					Don't have an account? <router-link to="/register" class="btn p-0 color-1">Sign Up</router-link>
				</div>

			</div>
			<div class="col-md-4"></div>
			
		</div>
		
	</div>


</section>



<section style="padding-top:30px;">
	
	<div style="text-align:center;">
		<router-link to="/" class="btn color-3">Terms & Conditions</router-link>
		<router-link to="/" class="btn color-3">Data Privacy Policy</router-link>
	</div>

</section>



</template>


<script>
	import axios from 'axios'
	export default{
		name : 'forgot-password',
		data(){
			return{
				email : '',
				message : '',
				text_class : ''
			}
		},

		methods : {
			async forgot_password(){
				if(!this.email){
					this.text_class ='text-danger'
					this.message = 'Enter your email address!'
					return
				}

				const res = await axios.post(this.$store.state.url+'send-password-link',{
					email : this.email
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})
				if(res=='success'){
					this.text_class = 'text-success'
					this.message = 'An email with a password reset link has been sent to you.Use it to reset your password.'
				}else{
				this.text_class ='text-danger'
				this.message = 'Email does not exist!'	
				}
			}
		}
	}

</script>


