import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://server.maifes.com/',
    socketio_server : 'https://server.maifes.com/',
    user_id : localStorage.getItem('user_id'),
    email : localStorage.getItem('email'),
    phone : localStorage.getItem('phone'),
    logged_in : localStorage.getItem('logged_in'),
    current_topic_hash : localStorage.getItem('current_topic_hash'),
    current_topic_name : localStorage.getItem('current_topic_name'),
    friend_id : localStorage.getItem('friend_id'),
    post_liked : localStorage.getItem('post_liked'),
    new_post_comment : localStorage.getItem('new_post_comment'),
    new_comment_reply : localStorage.getItem('new_comment_reply'),
    background : localStorage.getItem('background'),
    distance : localStorage.getItem('distance'),
    distance2 : localStorage.getItem('distance2'),
    was_topic_clicked : localStorage.getItem('was_topic_clicked'),
    created : localStorage.getItem('created'),
    topic_owner : localStorage.getItem('topic_owner'),
    chatting_in_progress : localStorage.getItem('chatting_in_progress'),
    am_in_the_waiting_room : localStorage.getItem('am_in_the_waiting_room'),
    am_in_klub_waiting_room : localStorage.getItem('am_in_klub_waiting_room'),
    loader_shown : false
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
