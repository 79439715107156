<template>


<div class="blur" style="z-index: -1;">
		<TopBar  />


		<div class="main-container">

		
	<SideBar  />


		<div class="main">



			<div class="row">

				<div class="col-md-6">
					
				
				</div>

				<div class="col-md-6">
					
				</div>

				
			</div>
			
		</div> 
	</div> 

</div>






<div style="position: fixed; top: 0; right: 0; width:100%;  padding-top: 50px;" :class=" { blur : show_modal }">
	

	<div class="container">

		<div class="row">

			<div class="col-md-3"></div>
			<div class="col-md-6">

							<div class="progress flex-row-reverse" style="margin-bottom: 10px;">
  <div class="progress-bar progress-bar-striped bg-warning" :class=" { 'bg-danger' : progress<50 } " role="progressbar" :style=" { width : progress+'%' }" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">  </div>
</div>


			
		<div :style=" { backgroundImage : 'url('+background+')' } " style="background-color:#fff; border-radius: 10px; background-size: cover;" class="shadow-lg">


			
		<!-- title section-->
		<div style="padding: 5px; border-top-left-radius:10px; border-top-right-radius: 10px; background-color: #fff;">


							<div class="row">

			<div class="col-md-8 pt-2" style="text-align:left;">
				<div>
				<h5> {{this.$store.state.current_topic_name}}  </h5>
			</div>
			</div>

			<div class="col-md-4" style="text-align:right;">
				<div>


			

					{{countdown}}

					<label class="dropdown">
  <button class="btn dropdown-toggl" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style="border: none;">
    <i class="bi bi-three-dots-vertical"></i>
  </button>
  <ul class="dropdown-menu shadow-lg animate__animated animate__fadeInUp" aria-labelledby="dropdownMenuButton1" style="border: none;">
    <li><button @click="show_modal=true" class="dropdown-item"> <i class="bi bi-x text-danger"></i> Leave</button></li>
  </ul>
</label>

				<button @click="show_modal=true" class="btn text-danger btn-lg"> <i class="bi bi-x"></i> </button>
				</div>
			</div>

		</div>
				


		</div>



		<!--messages section-->
		<div style="overflow: scroll; height: 350px; padding-top: 100px; padding-left: 30px; padding-right: 30px; padding-bottom: 60px; " @click="show_emoji=false">

			<div v-for="message in messages"  class="clearfix"  >
				
				<!--messages section-->


<div style="padding-top:30px; float: right;" v-if=" this.$store.state.user_id!=message.author">

<label  style="background-color:#fff; padding: 10px; border-radius: 10px; word-wrap: break-word; max-width: 400px; " class="shadow-lg">

 {{message.message}} 

 <br />

 <small style="float: right; font-size: 10px;">{{message.messageTime}}</small>

</label>

</div>






<div style="padding-top:30px; float: left;" v-if=" this.$store.state.user_id==message.author">
<label style="background-color:#1a1a1a; color:#fff; border-radius: 10px; padding:10px; max-width: 400px; word-wrap: break-word; " class="shadow-lg"> {{ message.message }}

	<br />

<small style="float:right; font-size: 10px;">
{{message.messageTime}}
</small>

</label>

</div>




			</div>



<div ref="bottomEl"></div>
</div>



<!--form section-->
<div style="padding: 10px; border-bottom-left-radius:10px; border-bottom-right-radius:10px; background-color: #f5f5f5; ">


<div class="input-group">
	<button @click="show_emoji=!show_emoji" class="btn btn-default"> <b> <i class="bi bi-emoji-smile" :class="{ 'bi bi-x' : show_emoji }" ></i></b> </button>
  <input @click="show_emoji=false" @keyup.enter="chat()" type="text" class="form-control" placeholder="Type a message" aria-label="Type a message" aria-describedby="button-addon2" style="border: none; border-radius: 5px;" v-model="message">
  <button @click="chat()" class="btn" type="button" id="button-addon2"> <i class="bi bi-chevron-right"></i> </button>
</div>

</div>
				

			</div>

			</div>
			<div class="col-md-3">
			
			</div>
			
		</div>
		
	</div>


</div>









<!--modal-->
<div v-if="show_modal" style="position:fixed; width: 100%; top: 0; right: 0; padding-top: 150px;">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4" style="padding:30px;">
			
			<div style="text-align:center; background-color: #fff; z-index: 1200; padding: 20px; border-radius: 10px; " class="shadow-lg animate__animated animate__zoomIn">
				<h1 class="color-1"> <i class="bi bi-info-circle"></i> </h1>
				<p>Add friend to  your klub?</p>

				<div style="padding-top:10px;">
					<button @click="reject_friend()" class="btn btn-2" style="margin-right:10px; min-width: 100px;">No</button>
					<button @click="add_friend()" class="btn btn-1" style="margin-right:10px; min-width:100px;">Yes</button>
					<button @click="show_modal=false" class="btn btn-2" style="100px;">Cancel</button>
				</div>

			</div>

		</div>
		<div class="col-md-4"></div>
		
	</div>

</div>








<!--modal-->
<div v-if="friend_left" style="position:fixed; width: 100%; top: 0; right: 0; padding-top: 150px;">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4" style="padding:30px;">
			
			<div style="text-align:center; background-color: #fff; z-index: 1200; padding:30px; border-radius: 10px; " class="shadow-lg  animate__animated animate__zoomIn">
				<h1 class="color-1"> <i class="bi bi-info-circle"></i> </h1>
				<p>Friend left.Add  friend to klub?</p>

				<div style="padding-top:10px;">
					<button @click="reject_friend()" class="btn btn-2" style="margin-right:10px; min-width: 100px; ">No</button>
					<button @click="add_friend()" class="btn btn-1" style="margin-right:10px; min-width: 100px; ">Yes</button>
				</div>

			</div>

		</div>
		<div class="col-md-4"></div>
		
	</div>

</div>





<!--modal-->
<div v-if="time_ended" style="position:fixed; width: 100%; top: 0; right: 0; padding-top: 150px;">
	
	<div class="row">

		<div class="col-md-4"></div>
		<div class="col-md-4">
			
			<div style="text-align:center; background-color: rgba(255, 255, 255, 1); z-index: 1200;" class="shadow-lg rounded p-2 m-3 animate__animated animate__zoomIn">
				<h1> <i class="bi bi-info-circle"></i> </h1>
				<h6>Chatting time ended.Add  friend to klub?</h6>

				<div style="padding-top:10px;">
				<button @click="reject_friend()" class="btn btn-2" style="margin-right:10px;">No</button>
					<button @click="add_friend()" class="btn btn-1" style="margin-right:10px;">Yes</button>
				</div>

			</div>

		</div>
		<div class="col-md-4"></div>
		
	</div>

</div>



<!-- emoji div -->


<div v-if="show_emoji" style="z-index:900; position: fixed; top: 0; right:0; padding-top: 150px; width: 100%;">

	<div class="container">

		<div class="row">

			<div class="col-md-4"></div>

			<div class="col-md-4">
				
				<div style="background-color:#f5f5f5;" class="p-2 m-2 rounded shadow-lg animate__animated animate__fadeInUp">

		<div class="row">
			<div class="col-md-6 col-6 mt-2">
				<h6>Emoji</h6>
			</div>
			<div class="col-md-6 col-6" style="text-align:right;">
				<button @click="show_emoji=!show_emoji" class="btn btn-lg p-0 text-danger"> <i class="bi bi-x"></i> </button>
			</div>
		</div>

		<div>
		
		<button class="btn p-1" v-for="emoji in emojis"  @click="print_emoji(emoji.emoji)"> {{emoji.emoji}} </button>

		</div>
		
	</div>

			</div>
			
		</div>
		
	</div>
	


</div>







	


</template>




<script setup>
	

</script>







<script>

	import SideBar from './layouts/SideBar'
	import TopBar from './layouts/TopBar'
	import { io } from "socket.io-client"
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import 'animate.css'


	export default{
		name : 'home',
		components : { SideBar, TopBar },
		data(){
			return{
				socket : io(this.$store.state.socketio_server),
				message : '',
				messages : [],
				show_modal : false,
				friend_left : false,
				countdown : '',
				x : '',
				time_ended : false,
				background : '',
				friends_in_room : [],
				progress : 0,
				show_emoji : false,
				emojis : [],
				
			}
		},

		methods : {

			async get_emojis(){

				const res  = await axios.get(this.$store.state.url+'get-emojis').then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})

				this.emojis = res 

			},


			print_emoji(emoji){
				this.message+=emoji
			},

			progress_bar(){
				let max_value = 10;
				let counter = 0
				setInterval(()=>{

					counter++
					this.progress = counter

				},1000)

			
				this.progress
			},


			stop_time(){
				clearInterval(this.x)
			},


			timer_2(){
				this.x = setInterval(()=>{
					this.timer()
				},1000)
			},


			timer(){
				//var distance = 180000
				var minutes = 0
				var seconds = 0

				if(!this.$store.state.distance){
					this.$store.state.distance = 300000
					localStorage.setItem('distance',300000)
				}

	minutes = Math.floor(( parseInt( this.$store.state.distance ) % (1000 * 60 * 60)) / (1000 * 60));
  seconds = Math.floor(( parseInt( this.$store.state.distance ) % (1000 * 60)) / 1000)

  //console.log('minute : '+minutes)
  //console.log('seconds : '+seconds)
  //console.log('distance : '+ this.$store.state.distance )

  if(minutes<10){
  	minutes = '0' + minutes.toString()
  }

  if(seconds<10){
  	seconds = '0'+seconds.toString()
  }

  this.countdown = minutes+':'+seconds



    let d = parseInt( this.$store.state.distance )
  d-=1000
   this.$store.state.distance = d  

   this.progress = (this.$store.state.distance/300000) * 100

   localStorage.setItem('distance',parseInt( this.$store.state.distance ))



  if ( parseInt( this.$store.state.distance ) < 1000) {
  	this.leave_chat()
  }
				
			},


			timer_old(){


				//var distance = 180000
				var minutes = ''
				var seconds = ''
				this.x = setInterval(()=>{
	minutes = Math.floor( parseInt(localStorage.getItem('distance')) % (1000 * 60 * 60) / (1000 * 60) );
  seconds = Math.floor( (   parseInt(localStorage.getItem('distance')) % (1000 * 60) ) / 1000)

  if(minutes<10){
  	minutes = '0' + minutes.toString()
  }

  if(seconds<10){
  	seconds = '0'+seconds.toString()
  }

  this.countdown = minutes+':'+seconds

  let d = parseInt(localStorage.getItem('distance'))
  d-=1000
   localStorage.setItem('distance', d)
  if (parseInt(localStorage.getItem('distance')) < 0) {
  	localStorage.setItem('distance',300000)
  	this.time_ended = true
    clearInterval(this.x)
  }
				},1000)
			},


			async reject_friend(){

				const res = axios.post(this.$store.state.url+'reject-friend',{
					user_id : this.$store.state.user_id,
					friend : this.$store.state.friend_id,
					current_topic_hash : this.$store.state.current_topic_hash
				}).then(function(response){
					return response.data 
				}).catch(function(error){
					//console.log(error)
				})
				this.leave_chat()

				//this.$router.push('/home')
				
			},

			async add_friend(){

				const res = axios.post(this.$store.state.url+'add-friend',{
					user_id : this.$store.state.user_id,
					friend : this.$store.state.friend_id,
					current_topic_hash : this.$store.state.current_topic_hash
				}).then(function(response){
					return response.data 
				}).catch(function(error){
					//console.log(error)
				})

				this.leave_chat()
				
			},



			chat(){

				if(!this.message){
					return
				}

				this.socket.emit('chat message', { 'message' : this.message, 'user_id' : this.$store.state.user_id, 'room' : this.$store.state.current_topic_hash } )
				this.message = ''

			},


			async get_messages(){
				const res = await axios.get(this.$store.state.url+'get-chat-messages/'+this.$store.state.current_topic_hash).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})
				this.messages = res 
				
				this.$refs.bottomEl?.scrollIntoView({ behavior: 'smooth' });
			},




			leave_chat(){

				this.stop_time()

				this.socket.emit('leave chat room', { 'room' : this.$store.state.current_topic_hash } )
				clearInterval(this.x)

					//reset waiting time
				localStorage.setItem('distance2','')
				this.$store.state.distance2 = ''
					//reset chat time
					localStorage.setItem('distance','')
					this.$store.state.distance = ''

					

				this.$router.push('/home')
			},

			check_friend(){
				this.socket.emit('get friend',{ 'room' : this.$store.state.current_topic_hash, 'user_id' : this.$store.state.user_id }) 

			}

		},


		created(){



			//load background
			if(this.$store.state.background){
				this.background = this.$store.state.url+'backgrounds/'+this.$store.state.background
			}else{
				this.background = '/assets/images/360_F_77634979_e2tCyG0BBFOAqA5Sd580GT5IH1Uw1JzV.jpeg'
			}


			//set the timer
			if(!parseInt(this.$store.state.distance)){
				localStorage.setItem('distance',300000)
				this.$store.state.distance = 300000
					////console.log('the distance is : '+this.$store.state.distance)
			}

			//get emojis
			this.get_emojis()




			//check if chatting is in progress, if not redirect to home page
			if(this.$store.state.chatting_in_progress!='yes'){

					//reset waiting time
				localStorage.setItem('distance2','')
				this.$store.state.distance2 = ''
					//reset chat time
					localStorage.setItem('distance','')
					this.$store.state.distance = ''

				this.$router.push('/home')
			}

			if( parseInt(this.$store.state.distance)>0 ){
				//timer
			this.timer_2()
			}

			



			//join chat room
			this.socket.emit('join chat room',{ 'room' : this.$store.state.current_topic_hash, 'user_id' : this.$store.state.user_id }) 

			//confirm you have joined
			this.socket.on('member joined',(data)=>{
					////console.log('my id is : ' + data)
				
			})


			//check if the friend has joined
			this.socket.on('friend is',(data)=>{

					if(data!=this.$store.state.user_id){ 
						this.$store.state.friend_id = data

						
						if(this.friends_in_room.includes(data)){
							//pass
							//do not add item to list, already exists!
						}else{
						this.friends_in_room = this.friends_in_room.concat(data)	
						}

						

						//remove excess people from the room
						if(this.friends_in_room.length>1){
							
							//remove the person who joined recently
								if(this.$store.state.user_id==this.$store.state.topic_owner){
									let person_to_remove = this.friends_in_room[1]
										
									//remove person from array
									this.friends_in_room = this.friends_in_room.filter(function(item) {
									    return item !== person_to_remove
									})
									//send message to remove the person
			this.socket.emit('person to remove',{ 'room' : this.$store.state.current_topic_hash, 'person_to_remove' : person_to_remove })
			

								}

						}
						///


						localStorage.setItem('friend_id',data)
					}

			})

			//get friend after 3 seconds
			setTimeout(()=>{
				this.check_friend()
				
			},3000)

			//count number of people in you chat room after 5s.should be more than 0
			
			setTimeout(()=>{
				if(!this.$store.state.friend_id){
								Swal.fire({
  title: "Notification",
  text: "No one was found in the chat room",
  icon: "info"
})
					this.$router.push('/home')
				}
				
			},5000)


			//check if someone has left the chatting room so that you also leave
			this.socket.on('someone left chat room',(data)=>{

				this.friend_left = true

			})

			//check for new messages
			this.socket.on('new message',(data)=>{
				this.get_messages()
			})


			//check if there is someone to remove
			this.socket.on('person to remove',(data)=>{
				if(this.$store.state.user_id==data){

					Swal.fire({
  title: "Notification",
  text: "This topic is already picked",
  icon: "info"
})

						//reset waiting time
				localStorage.setItem('distance2','')
				this.$store.state.distance2 = ''
					//reset chat time
					localStorage.setItem('distance','')
					this.$store.state.distance = ''

			this.$router.push('/join-chat')

				}
			})


			
			

		
		},

		mounted(){


			//scroll chat div to top automatically
			this.get_messages()
			setTimeout(()=>{
				this.$refs.bottomEl?.scrollIntoView();
			},100)

			
	

		}




	}
</script>




<style scoped>
	
	.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

</style>

