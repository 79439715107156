<template>


<div v-if="this.$store.state.loader_shown">
	


	<section style="padding-top:100px;">
	
	<div class="container">

		<div class="row">
			<div class="col-md-2"></div>

		<div class="col-md-4 animate__animated animate__bounceIn animate__slower" style="background-image: url('/assets/images/Untitled design.png'); background-size: contain; background-repeat: no-repeat; background-position: center;" >
	
			</div>

			<div class="col-md-4" style="padding-bottom:0px; padding-top: 0px; ">
				
				<div style="border: solid 1px  #f0f0f0; padding-top: 50px; padding-bottom: 50px; padding-left:30px; padding-right: 30px; background-color: #fff; border-radius: 15px; ">

					<div style="padding-bottom: 30px; text-align: center;">
						<img src="/assets/images/logo.png" class="logo">
					</div>

					<p class="text-danger" v-if="message"> {{message}} </p>
					
					<form method="post" @submit.prevent="login()">
						
						<div>
							<input v-model="email" type="text" placeholder="Email address" class="form-control">
						</div>


						<div class="mt-2">
							<input v-model="password" type="password" placeholder="Password" class="form-control">
						</div>

						<div class="mt-2">
							<button class="btn btn-1 w-100">Login</button>
						</div>

						<div class="mt-2" style="text-align:center;">
							<h6>OR</h6>
						</div>


						<div class="mt-2">
							<button type="button" @click="google_login()" class="btn btn-2 w-100"> <i class="bi bi-google"></i> Sign In With Google</button>
						</div>

						<div class="mt-3" style="text-align: center;">
							<router-link to="/forgot-password" class="btn p-0 ">Forgot password</router-link>
						</div>

					</form>

				</div>


				<div style="border: solid 1px  #f0f0f0; text-align: center; padding:30px; background-color: #fff; border-radius: 15px; " class="mt-3">
					Don't have an account? <router-link to="/register" class="btn p-0 color-1">Sign Up</router-link>
				</div>

			</div>

			<div class="col-md-4"></div>
			
		</div>
		
	</div>


</section>




</div>



<div style="text-align:center; padding-top:200px;" v-if="this.$store.state.loader_shown==false ">
	
	<img src="/assets/images/Maifes Loading Refined (1).gif" style="height:300px;">


</div>



</template>


<script>
	import axios from 'axios'
	import { googleSdkLoaded } from "vue3-google-login"
	import 'animate.css'


	export default{
		name : 'login',
		data(){
			return{
				email : '',
				password : '',
				message : '',
				user_details : null,
				google_email : ''
			}
		},

		methods : {

			 google_login() {
      googleSdkLoaded(google => {
        google.accounts.oauth2
          .initCodeClient({
            client_id:
              "1005035349-r5jl8j1pe9sk7mmcmj9gngeshobt3u5h.apps.googleusercontent.com",
            scope: "email profile openid",
            redirect_uri: "http://127.0.0.1:8080",
            callback: response => {
              if (response.code) {
              	this.sendCodeToBackend(response.code)
                //console.log(response);
              }
            }
          })
          .requestCode();
      });
    },


        async sendCodeToBackend(code) {
      try {
        const response = await axios.post(
          "https://oauth2.googleapis.com/token",
          {
            code,
            client_id:
              "1005035349-r5jl8j1pe9sk7mmcmj9gngeshobt3u5h.apps.googleusercontent.com",
            client_secret: "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",
            redirect_uri: "postmessage",
            grant_type: "authorization_code"
          }
        );

        const accessToken = response.data.access_token;
        //console.log(accessToken);

        // Fetch user details using the access token
        const userResponse = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (userResponse && userResponse.data) {
          // Set the userDetails data property to the userResponse object
          this.user_details = userResponse.data;
           this.google_email = this.user_details.email
           this.google_login_now()
        } else {
          // Handle the case where userResponse or userResponse.data is undefined
          console.error("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Token exchange failed:", error.response.data);
      }
    },



    async google_login_now(){

				const res = await axios.post(this.$store.state.url+'google-login',{
					email : this.google_email,
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{
						localStorage.setItem('email',data.email)
						localStorage.setItem('phone',data.phone)
						localStorage.setItem('user_id',data.userId)
						this.$store.state.phone = data.phone
						this.$store.state.user_id = data.userId
						localStorage.setItem('logged_in','yes')
						this.$store.state.logged_in = 'yes'

						localStorage.setItem('background',data.background)
						this.$store.state.background = data.background

						localStorage.setItem('created',data.created)
						this.$store.state.created = data.created

					})
					this.$router.push('/home')

				}else{
					this.message = 'Wrong email or password!'
				}
			},


			async login(){

				if(!this.email){
					this.message = 'Invalid email!'
					return
				}

				if(!this.password){
					this.message = 'Invalid password!'
					return
				}

				const res = await axios.post(this.$store.state.url+'login',{
					email : this.email,
					password : this.password
				}).then(function(response){
					return response.data
				}).catch(function(error){
					//console.log(error)
				})

				if(res.length>0){

					res.forEach((data)=>{
						localStorage.setItem('email',data.email)
						localStorage.setItem('phone',data.phone)
						localStorage.setItem('user_id',data.userId)
						this.$store.state.user_id = data.userId
						localStorage.setItem('logged_in','yes')
						this.$store.state.logged_in = 'yes'

						localStorage.setItem('background',data.background)
						this.$store.state.background = data.background

						localStorage.setItem('created',data.created)
						this.$store.state.created = data.created

					})
					this.$router.push('/home')

				}else{
					this.message = 'Wrong email or password!'
				}
			}


		},

		created(){

			setTimeout(()=>{

				this.$store.state.loader_shown = true

			}, 2000);

		}
	}

</script>


